@use 'global-styles/marble/base/base.scss';
$slide-size: 400px;

.slider {
	display: flex;
	gap: var(--spacing-xxs);
	justify-content: flex-start;
	overflow-x: scroll;
	padding: 0 var(--spacing-component-margin);
	will-change: scroll-position;

	&::-webkit-scrollbar {
		display: none;
	}
}

.wrapper {
	position: relative;

	&:has(*:focus-visible) .accessibilityMessage {
		display: block;
	}
}

.accessibilityMessage {
	background-color: var(--color-focus-state-background);
	color: var(--color-grey-900);
	display: none;
	font-size: var(--textsize-xs);
	font-weight: 500;
	margin: 0 var(--spacing-component-margin);
	padding: var(--spacing-micro);
	position: absolute;
	top: 0;
	width: auto;
	z-index: base.z('positive-default');
}

.button { //TODO - Componentize these arrow buttons.
	@include base.button-reset();
	align-items: center;
	background-color: var(--color-site-background);
	border: 1px solid var(--color-grey-400);
	border-radius: 50%;
	box-shadow: 0 0 15px rgb(0, 0, 0, 0.25); //TODO - lets standardize shadows.
	color: currentColor;
	display: none;
	height: 48px;
	position: absolute;
	top: 40%;
	width: 48px;
	z-index: base.z('positive-default');
	
	svg {
		height: 16px;
		width: 16px;
	}
	
	&:focus-visible,
	&:hover {
		background-color: var(--color-text);
		color: var(--color-site-background);
	}

	@media screen and (min-width: base.$breakpoint-m) {
		.wrapper:hover & {
			display: flex;
		}
	}
}

.backButton {
	left: var(--spacing-s);
	transform: rotate(180deg);
}

.forwardButton {
	right: var(--spacing-s);
}
